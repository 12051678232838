import request from '@/utils/request'

// 查询广告位管理列表
export function listAd(query) {
  return request({
    url: '/work/ad/list',
    method: 'get',
    params: query
  })
}

// 查询广告位管理详细
export function getAd(id) {
  return request({
    url: '/work/ad/' + id,
    method: 'get'
  })
}

// 新增广告位管理
export function addAd(data) {
  return request({
    url: '/work/ad',
    method: 'post',
    data: data
  })
}

// 修改广告位管理
export function updateAd(data) {
  return request({
    url: '/work/ad/update',
    method: 'post',
    data: data
  })
}

// 删除广告位管理
export function delAd(id) {
  return request({
    url: '/work/ad/remove/' + id,
    method: 'get'
  })
}

// 导出广告位管理
export function exportAd(query) {
  return request({
    url: '/work/ad/export',
    method: 'get',
    params: query
  })
}